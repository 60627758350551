export const projects = [
  {
    id: 9,
    title: "Global Fitness",
    date: "5 mei 2024 - 7 mei 2024",
    description:
      "Global Fitness E-commerce adalah platform online yang menyediakan berbagai produk dan peralatan kebugaran dari seluruh dunia, menawarkan navigasi mudah, rekomendasi produk, ulasan dan rating, pembayaran aman, serta pengiriman global. Dengan Tailwind CSS, Global Fitness E-commerce memberikan pengalaman belanja online yang efisien dan estetis, mendukung pengguna dalam mencapai tujuan kebugaran mereka.",
    image:
      "https://github.com/rizqiirkhamm/E-commerce-Tailwind/assets/84420914/cd0c34f0-e298-45f5-9034-2b56b745181c",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
      "Tailwind"
    ],
    category: "web",
    github: "https://github.com/rizqiirkhamm/E-commerce-Tailwind",
    webapp: "https://ecommerce-tailwind-rizqi.netlify.app",
  },
  {
    id: 0,
    title: "Brainee",
    date: "20 maret 2024 - 25 maret 2024",
    description:
      "Brainne adalah sebuah situs web interaktif yang menyediakan berbagai soal untuk menguji pengetahuan pengguna dalam dua bidang utama: Geografi dan Teknologi. Pada akhir setiap sesi kuis, pengguna akan menerima skor berdasarkan kinerja mereka, dan skor tertinggi (highscore) akan disimpan dan ditampilkan untuk memotivasi pengguna agar terus meningkatkan hasil mereka.",
    image:
      "https://github.com/rizqiirkhamm/E-commerce-Tailwind/assets/84420914/2a0de31a-069a-4b9f-8df5-7afe5c2ea850",
    tags: ["HTML", "CSS", "JavaScript",],
    category: "web",
    github: "https://github.com/rizqiirkhamm/Brainee_ICOMFEST-2024",
    webapp: "https://brainne-icomfest.netlify.app/",
    member: [
      {
        name: "Ammar Zahran Syafiq",
        img: "https://github.com/rizqiirkhamm/E-commerce-Tailwind/assets/84420914/c0cc1039-97f5-4463-8ef0-df76bc8b088b",
        linkedin: "https://www.linkedin.com/in/ammar-zahran-syafiq-ab2363233/",
        github: "https://github.com/ammarzhrn",
      },
      {
        name: "Rizqi irkham maulana",
        img: "https://github.com/rizqiirkhamm/E-commerce-Tailwind/assets/84420914/7aef77a5-4c41-484e-bca7-dc663fba3b0e",
        linkedin: "https://www.linkedin.com/in/rizqi-irkham-maulana/",
        github: "https://github.com/rizqiirkhamm/",
      },
    ],
  },
  {
    id: 1,
    title: "FisheryGo-Web",
    date: "Oct 10 2023 - Oct 16 2023",
    description:
      "FisheryGo adalah sebuah platform yang didedikasikan untuk dunia perikanan, dirancang untuk menyediakan informasi penting dan alat-alat yang membantu para nelayan, petani ikan, dan penggemar perikanan. Situs ini menampilkan data terkini tentang perikanan, tips dan trik untuk budidaya ikan, serta berita dan artikel yang relevan dengan industri perikanan.",
    image:
      "https://github.com/rizqiirkhamm/FisheryGo-Web/assets/84420914/10b43cb6-c780-4018-ba6d-2e016c2b0405",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
    ],
    category: "web",
    github: "https://github.com/rizqiirkhamm/FisheryGo-Web",
    webapp: "https://fisherygo.netlify.app/",
    member: [
      {
        name: "Arkan Naufal",
        img: "https://fisherygo.netlify.app/assets/images/team/arkan.jpg",
        linkedin: "https://www.linkedin.com/in/arkannaufl/",
        github: "https://github.com/arkannaufl",
      },
      {
        name: "Vallian Sayoga Chandra",
        img: "https://fisherygo.netlify.app/assets/images/team/valian.png",
        linkedin: "https://www.linkedin.com/in/vallian-sayoga/",
        github: "https://github.com/valliansayoga",
      },
      {
        name: "Rizqi irkham maulana",
        img: "https://github.com/rizqiirkhamm/E-commerce-Tailwind/assets/84420914/7aef77a5-4c41-484e-bca7-dc663fba3b0e",
        linkedin: "https://www.linkedin.com/in/rizqi-irkham-maulana/",
        github: "https://github.com/rizqiirkhamm/",
      },
    ],
  },
];
