// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar{background-color:#202324;color:#aba499}::-webkit-scrollbar-thumb{background-color:#454a4d}::-webkit-scrollbar-thumb:hover{background-color:#575e62}::-webkit-scrollbar-thumb:active{background-color:#484e51}::-webkit-scrollbar-corner{background-color:#181a1b}*{scrollbar-color:#202324 #454a4d}body{margin:0;font-family:"Inter",sans-serif;visibility:hidden;overflow-x:hidden}a{text-decoration:none;color:inherit}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA,oBACE,wBAAA,CACA,aAAA,CAGF,0BACE,wBAAA,CAGF,gCACE,wBAAA,CAGF,iCACE,wBAAA,CAGF,2BACE,wBAAA,CAGF,EACE,+BAAA,CAGF,KACE,QAAA,CACA,8BAAA,CACA,iBAAA,CACA,iBAAA,CAGF,EACE,oBAAA,CACA,aAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;800&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');\n\n\n::-webkit-scrollbar {\n  background-color: #202324;\n  color: #aba499;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #454a4d;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #575e62;\n}\n\n::-webkit-scrollbar-thumb:active {\n  background-color: #484e51;\n}\n\n::-webkit-scrollbar-corner {\n  background-color: #181a1b;\n}\n\n* {\n  scrollbar-color: #202324 #454a4d;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Inter', sans-serif;\n  visibility: hidden;\n  overflow-x: hidden;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n\n// @font-face {\n//   font-family: 'Proxima Nova';\n//   src: url(\"/src/assets/fonts/ProximaNova-Bold.ttf\") format(\"truetype\");\n//   font-weight: bold;\n// }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
