import React, { useState } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';

const Projects = ({ setOpenModal }) => {
  const [toggle, setToggle] = useState('all');


  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          I have worked on a wide range of projects. From web apps to android apps. Here are some of my projects.
        </Desc>
        <ToggleButtonGroup>
          <ToggleButton onClick={() => setToggle('all')} selected={toggle === 'all'}>
            All
          </ToggleButton>
          <ToggleButton onClick={() => setToggle('web')} selected={toggle === 'web'}>
            Web
          </ToggleButton>
          <ToggleButton onClick={() => setToggle('android')} selected={toggle === 'android'}>
            Android
          </ToggleButton>
        </ToggleButtonGroup>
        <Divider />
        <CardContainer>
          {toggle === 'all' && projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              setOpenModal={setOpenModal}
            />
          ))}
          {toggle !== 'all' && projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                setOpenModal={setOpenModal}
              />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
