import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import PreLoader from "../components/PreLoader";
import { NavLink } from "react-router-dom";
import { ReactComponent as ScrollSVG } from "../assets/images/down-scroll.svg";
import { ReactComponent as Triangle } from "../assets/images/triangle.svg";
import Footer from "../components/Footer";
import AnimateEl from "../components/AnimateEl";
import Projects from "../components/Projects";
import ProjectDetails from "../components/ProjectDetails";

const transition = { duration: 1, ease: "easeInOut", delay: 0.8 };

const text1 = "Rizqi Irkham Maulana";
const text2 = "I'm a Backend Developer in New York, and I'm very passionate and dedicated to my work.";

const Home = () => {
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    document.title = "Rizqi — Developer";
  }, []);

  return (
    <>
      <PreLoader />

      <motion.div exit={{ height: "100vh", transition }} className="transition2" />

      <div className="landing">
        <section className="landing__top">
          <div className="sub">Back-End Developer</div>
          <p className="landing__main">
            <span className="text-con">
              {text1.split("").map((t, i) => (
                <span key={i} className="text">
                  {t}
                </span>
              ))}
              <br />
              {text2.split("").map((t, i) => (
                <span key={i} className="text landing__description">
                  {t}
                </span>
              ))}
            </span>
          </p>
          <p className="landing__main2">
            Rizqi Irkham Maulana <br /> I'm a Backend Developer in New York, and I'm very passionate and dedicated to my work.
          </p>
          <div className="links">
            <NavLink className="item" to="/about">
              About Me
            </NavLink>
            <span className="item">—</span>
            <span className="item">
              <a href="#projects">My Projects</a>
            </span>
          </div>

          <div className="scroll-down">
            <ScrollSVG />
          </div>

          <div className="shapes">
            <div className="main-circle" />
            <div className="shape shape-1" />
            <div className="shape shape-2">
              <Triangle />
            </div>
            <div className="shape shape-3" />
            <div className="shape shape-4" />
          </div>
        </section>

        <section id="projects" className="landing__work">
          <AnimateEl>
            <div className="title" id="projects">
              My <br /> Projects -
            </div>
          </AnimateEl>

          <Projects openModal={openModal} setOpenModal={setOpenModal} />

          {openModal.state && (
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          )}
        </section>
        
        <Footer />
      </div>
    </>
  );
};

export default Home;
