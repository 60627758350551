import React, { useEffect } from "react";
import { motion } from "framer-motion";
// import { fadeUp } from "../animations";
import gsap from "gsap";
import Footer from "../components/Footer";
import Aboutimg from "../assets/images/profile.jpeg";

const transition = { duration: 1, ease: "easeInOut", delay: 0.8 };

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    document.title = "Rizqi — About Me";
    // fadeUp(".about-inner", 0.8);
    gsap.from(".about__inner", {
      duration: 0.2,
      delay: 1,
      opacity: 0,
      ease: "Power4.out",
    });
  }, []);

  return (
    <>
      <motion.div
        initial={{ height: "100%" }}
        // animate={{ height: "0%", transition: { ...transition, delay: 1 } }}
        className="transition"
      />
      <motion.div
        exit={{ height: "100%", transition }}
        className="transition2"
      />
      <motion.div
        initial={{ height: 0, visibility: "hidden" }}
        animate={{
          height: "100%",
          visibility: "visible",
          transition: { ...transition, duration: 2, delay: -0.2 },
        }}
        className="about"
      >
        <div className="about__inner">
          <div className="section__about">
            <div className="title__about">
              <h2 className="section__title">About Me</h2>
              <span className="section__subtitle">My Introduction</span>
            </div>
            <div className="about about__container container grid">
              <img
                class="h-auto max-w-xl transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0"
                src={Aboutimg}
                alt="deskripsi gambar"
              />

              <div className="about__data">
                {/* <Info/> */}
                <p class="about__description">
                  Get started with an enterprise-level, professionally designed,
                  fully responsive, and HTML semantic set of web pages, sections
                  and over 400+ components crafted with the utility classes from
                  Tailwind CSS and based on the Flowbite component library
                </p>
                <div className="button__about">
                  <button
                    type="button"
                    class="text-white hover:text-black border border-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                  >
                    My Cv
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="about__bottom">
            <div class="experience">
              <div class="stack main-title">
                <span>Skills.</span>
              </div>

              <div class="companies">
                <div class="company">
                  <div class="name tech">Just:</div>
                  <ul class="tasks">
                    <li>Laravel</li>
                    <li>Php</li>
                    <li>JavaScript</li>
                    <li>React Js</li>
                    <li>MySQL</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="experience">
              <div class="main-title">
                <span>experience.</span>
              </div>

              <div class="companies">
                <div class="company">
                  <div class="name">School (IDN Boarding SChool)</div>
                  <div class="year">June 2024 - desember 2025(1 Year)</div>
                  <ul class="tasks">
                    <li>
                      I School on IDN Boaring school for take knowledge
                      Programmer. And i want become :
                    </li>
                    <li>Backend Developer</li>
                    <li>android Developer</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </motion.div>
    </>
  );
};

export default About;
